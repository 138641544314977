body,
html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

.navbar {
    transition: background-color 0.3s ease;
}

.navbar-nav {
    font-size: 0.9rem;
    font-weight: 600;
}

.nav-item :hover {
    color: #005993 !important;
    transition: all 0.3s ease-in-out;
}

.nav-item-active {
    position: relative;
    margin: 0;
    padding: 0;
}

.nav-item-active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #005993;
    border-radius: 2px;
}

.bg-custom {
    background-color: #005993 !important;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: 600;
}

.bg-cover {
    background-size: cover;
}

.bg-center {
    background-position: center;
}

.transition-opacity {
    transition: opacity 0.5s ease-in-out;
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.custom-carousel {
    top: -10% !important;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.fs-custom-title {
    font-size: 2.3rem !important;
    font-weight: bold !important;
}

.fs-custom-text {
    font-size: 0.9rem !important;
    font-weight: bold !important;
}

.img-custom {
    width: 200px !important;
    height: auto !important;
}

.custom-bottom {
    bottom: 20px !important;
    gap: 10px !important;
}

.company-data-title {
    font-size: 2rem !important;
    font-weight: 100 !important;
    color: #6C737F !important;
}

.btn-circle-pointer {
    border-radius: 50% !important;
    color: #6C737F;
    cursor: default;
    pointer-events: none;
}

.btn-circle {
    border-radius: 50% !important;
}

.input,
.text_area {
    width: 100%;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    transition: border-color 0.2s ease;
    resize: none;
}

.input:focus,
.text_area:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.input::placeholder {
    color: #6b7280;
}

.text_area::placeholder {
    color: #6b7280;
}

.btn-remove {
    border: none;
    background-color: transparent;
    color: #333;
    cursor: pointer;
}

.selecionar_area {
    position: absolute;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    list-style: none;
    margin-top: 5px;
    overflow-y: auto;
    max-height: 200px;
    z-index: 10;
}

/* .w-100 {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
} */

/* Estiliza cada item da lista de áreas */
.area_item {
    padding: 10px;
    font-size: 14px;
    color: #333;
    transition: background 0.2s ease-in-out;
}

/* Efeito hover ao passar o mouse */
.area_item:hover {
    background: #f5f5f5;
    cursor: pointer;
    color: #007bff;
}

/* Deixa a área selecionada destacada */
.area_item.selected {
    background: #007bff;
    color: #fff;
    font-weight: bold;
}

.formulario-grupo {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.grupo-titulo {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.campos-grupo {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.badge-secondary {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    margin-right: 8px;
    margin-bottom: 8px;
}

.btn-circle,
.btn-circle-pointer {
    width: 40px;
    height: 40px;
    margin: 0 10px;
}

.btn-remove {
    background-color: transparent;
    border: none;
    padding: 2px;
    margin: 0;
    cursor: pointer;
}

.passo-item {
    margin-bottom: 20px;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.passo-item:last-child {
    border-bottom: none;
}

.formulario {
    min-height: 800px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    /* Desfoque no fundo */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content-message {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    max-height: 50vh;
    overflow-y: auto;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
    /* Para Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    /* Para Firefox */
}

/* Scroll estilizado para WebKit (Chrome, Edge, Safari) */
.modal-content-message::-webkit-scrollbar {
    width: 6px;
    /* Define a largura da barra */
}

.modal-content-message::-webkit-scrollbar-track {
    background: transparent;
    /* Fundo transparente */
}

.modal-content-message::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    /* Cor da barra de rolagem */
    border-radius: 10px;
    transition: background 0.3s ease;
}

.modal-content-message::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
    /* Cor ao passar o mouse */
}


.modal-content-message h2 {
    white-space: pre-wrap;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
}

.modal-section {
    margin-bottom: 15px;
}

.modal-actions button {
    margin-left: 10px;
    margin-top: 60px;
}

body.modal-open {
    overflow: hidden;
    /* Impede a rolagem da página */
}

body.modal-open nav {
    filter: blur(5px);
    /* Desfoque na navbar */
}

body.modal-open .container {
    filter: blur(5px);
    /* Desfoque no conteúdo da página */
}

/* Media Queries */
@media (max-width: 1200px) {
    /* .container {
        padding: 15px;
    } */

    .formulario-grupo {
        max-width: 100%;
        padding: 15px;
    }

    .input,
    .text_area {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    /* .container {
        padding: 10px;
    } */

    .formulario-grupo {
        padding: 12px;
    }

    .input,
    .text_area {
        padding: 8px;
    }

    .btn-circle,
    .btn-circle-pointer {
        width: 35px;
        height: 35px;
        margin: 0 8px;
    }
}

@media (max-width: 480px) {
    /* .container {
        padding: 8px;
    } */

    .formulario-grupo {
        padding: 10px;
    }

    .input,
    .text_area {
        padding: 6px;
    }

    .btn-circle,
    .btn-circle-pointer {
        width: 30px;
        height: 30px;
        margin: 0 6px;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    /* Fundo branco */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    /* Cor azul Bootstrap */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}